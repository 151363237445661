import React from "react"
import { Box } from "rebass"
import { ExternalLink } from "@fragments"
import { Container } from "@components"

const PreContentCTA = () => (
  <Box mt={4} mb={-3}>
    <Container maxWidth={940}>
      <Box width={1} px={"36px"} py={4} backgroundColor={"#f8f8f8"}>
        Still using AWS console to work with DynamoDB? 🙈 <br />
        <br /> Time to <b>10x your DynamoDB productivity</b> with Dynobase{" "}
        <ExternalLink to={"https://dynobase.dev/"}>[learn more]</ExternalLink>{" "}
      </Box>
    </Container>
  </Box>
)

export default PreContentCTA
