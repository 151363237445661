import React, { useState } from "react"
import { FooterWithCTA, SEO, Layout, Hero } from "@components"
import MDXContent from "@components/pages/content/MDXContent.js"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import PreContentCTA from "@components/PreContentCTA"
import format from "date-fns/format"
import { graphql } from "gatsby"

export default function Template({ data }) {
  const { mdx } = data
  const { frontmatter, body, headings } = mdx
  const prettyPublishedDate = format(new Date(frontmatter.publishedDate), "PPP")
  return (
    <>
      <Layout hidePreheaderCTA={frontmatter.skipPreHeaderCTA}>
        <SEO
          title={frontmatter.metaTitle}
          description={frontmatter.metaDescription}
          canonical={`https://dynobase.dev${frontmatter.path}`}
        />
        <Hero
          title={frontmatter.title}
          includeAuthor={true}
          publishedOn={prettyPublishedDate}
          authorName={frontmatter.authorName}
          authorLink={frontmatter.authorLink}
          authorImage={frontmatter.authorImage}
        />
        <TableOfContents headings={headings} />
        {!frontmatter.skipIntroAd && <PreContentCTA />}
        <MDXContent body={body} maxWidth={940} />
        <FooterWithCTA />
      </Layout>
    </>
  )
}

const TableOfContents = ({ headings }) => {
  const [headerStyle, setHeaderStyle] = useState({ opacity: 0 })

  useScrollPosition(
    ({ currPos }) => {
      const isVisible = currPos.y < -500

      const shouldBeStyle = {
        opacity: isVisible ? 1 : 0,
      }

      if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) {
        return
      }

      setHeaderStyle(shouldBeStyle)
    },
    [headerStyle]
  )

  return (
    <ul
      class="table-of-contents"
      style={{
        maxWidth: "260px",
        zIndex: 2,
        position: "fixed",
        right: "3%",
        top: "7%",
        ...headerStyle,
      }}
    >
      {(headings ?? []).map(nav => {
        const navTitleParts = nav.value.split("</a>")
        const title = nav.value.split("</a>").slice(-1)[0]
        const href =
          navTitleParts.length === 2
            ? `#${navTitleParts[0].split('"')[1]}`
            : nav.url

        if (!href) {
          return <></>
        }

        return (
          <li
            style={{
              marginLeft: 10 * nav.depth,
              listStyleType: "none",
              lineHeight: "1.4",
            }}
          >
            <a
              href={href}
              className=""
              style={{
                color: "#777",
                fontSize: "14px",
                lineHeight: "0.8",
                textDecorationColor: "#ddd",
              }}
            >
              {title}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      tableOfContents
      headings {
        depth
        value
      }
      frontmatter {
        publishedDate
        path
        metaTitle
        metaDescription
        title
        authorName
        authorLink
        authorImage
        skipIntroAd
        skipPreHeaderCTA
      }
    }
  }
`
