import React from "react"
import { Container } from "@components"
import { Box } from "rebass"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import CodeBlock from "@components/codeBlock.js"
import MDXImage from "@components/mdxImage.js"
import DynamoDBReleasesTable from "@components/pages/dynamodb/Releases.js"
import { ExternalLink } from "@fragments"
import { Toaster } from "react-hot-toast"

const components = {
  code: props => {
    const language = props.className.replace(/language-/, "")
    return <CodeBlock language={language} {...props} />
  },
  DynamoDBReleasesTable,
  MDXImage,
  ExternalLink,
}

const Content = ({ body, maxWidth }) => {
  return (
  <Container maxWidth={maxWidth}>
    <Box
      mt={[32, 62]}
      sx={{ fontSize: "1.15em", lineHeight: 1.75 }}
      className="mdx-article"
    >
      <MDXProvider components={components}>
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
    </Box>
    <Toaster />
  </Container>
)
}

export default Content
