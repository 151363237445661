import React from "react"
import { highlight, languages } from "prismjs"
import "prismjs/components/prism-clike"
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-json"
import "prismjs/components/prism-python"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-yaml"
import "prismjs/components/prism-java"
import "prismjs/components/prism-csharp"
import "prismjs/components/prism-go"
import "prismjs/components/prism-rust"
import "prismjs/components/prism-sql"
import "prismjs/components/prism-hcl"
import "prismjs/components/prism-markup-templating"
import "prismjs/components/prism-php"
import Editor from "react-simple-code-editor"
import toast from "react-hot-toast"

const codeBlock = ({ children, language }) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Editor
        value={children.substring(0, children.length - 1)}
        highlight={code => highlight(code, languages[language], language)}
        style={{
          fontFamily: "hack,monospace,sans-serif",
          fontSize: 14,
          padding: "18px 24px 18px 20px",
          borderRadius: "5px",
          outline: 0,
          backgroundColor: "#282a36",
          marginBottom: "5px",
          color: "rgb(80, 250, 123)",
        }}
      />
      <div
        className="code-block-copy-to-clipboard"
        onClick={() => {
          navigator.clipboard.writeText(children).then(
            function() {
              console.log("Copied to clipboard!")
              toast.success("Copied to clipboard!")
            },
            function(error) {
              console.warn("Failed to copy text to clipboard")
            }
          )
        }}
        style={{
          top: "16px",
          position: "absolute",
          height: "24px",
          width: "24px",
          right: "16px",
          cursor: "pointer",
          background: "#333",
          borderRadius: "4px",
          padding: "4px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#bbb"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
          />
        </svg>
      </div>
    </div>
  )
}

export default codeBlock
