module.exports = [
    {
      "title": "Amazon DynamoDB Standard Infrequent Access table class is now available in AWS GovCloud (US) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2022/06/amazon-dynamodb-standard-infrequent-access-table-class-govcloud-us/", 
      "date": "6/15/2022"
    },
    {
      "title": "Announcing enhanced integration with Service Quotas for Amazon DynamoDB",
      "link": "https://aws.amazon.com/about-aws/whats-new/2022/06/enhanced-integration-service-quotas-amazon-dynamodb/", 
      "date": "6/15/2022"
    },
    {
      "title": "Amazon DynamoDB Standard-Infrequent Access table class is now available in AWS Asia Pacific (Jakarta) Region",
      "link": "https://aws.amazon.com/about-aws/whats-new/2022/06/amazon-dynamodb-standard-infrequent-access-table-class-aws-asia-pacific-jakarta-region/", 
      "date": "6/07/2022"
    },
    {
      "title": "Amazon DynamoDB increases default service quotas to simplify use of large numbers of tables",
      "link": "https://aws.amazon.com/about-aws/whats-new/2022/03/amazon-dynamodb-default-quotas-large-tables/", 
      "date": "3/09/2022"
    },
    {
      "title": "Amazon DynamoDB now supports the limit request option for PartiQL operations",
      "link": "https://aws.amazon.com/about-aws/whats-new/2022/03/amazon-dynamodb-limit-request-partiql-operations/", 
      "date": "3/08/2022"
    },
    {
      "title": "Now DynamoDB can return the throughput capacity consumed by PartiQL API calls to help you optimize your queries and throughput costs",
      "link": "https://aws.amazon.com/about-aws/whats-new/2022/01/dynamodb-partiql-api-queries-costs/", 
      "date": "1/18/2022"
    },
    {
      "title": "Amazon DynamoDB announces the new Amazon DynamoDB Standard-Infrequent Access table class, which helps you reduce your DynamoDB costs by up to 60 percent",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/12/amazon-dynamodb-standard-infrequent-access-table-class/", 
      "date": "12/01/2021"
    },
    {
      "title": "Amazon DynamoDB now helps you meet regulatory compliance and business continuity requirements through enhanced backup features in AWS Backup",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/11/amazon-dynamodb-requirements-aws-backup/", 
      "date": "11/24/2021"
    },
    {
      "title": "AWS Amplify announces a redesigned, more extensible GraphQL Transformer for creating app backends quickly",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/11/aws-amplify-redesigned-graphql-transformer-app-backends/", 
      "date": "11/23/2021"
    },
    {
      "title": "Amazon DynamoDB now provides you more granular control of audit logging by enabling you to filter Streams data-plane API activity in AWS CloudTrail",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/09/amazon-dynamodb-granular-control-audit-logging-streams-data-plane-api-activity-aws-cloudtrail/", 
      "date": "9/22/2021"
    },
    {
      "title": "The Amazon DynamoDB Accelerator (DAX) SDK for Java 2.x is now available",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/07/amazon-dynamodb-accelerator-dax-sdk-for-java-2-x-is-available/", 
      "date": "7/29/2021"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) is now available in the China (Beijing) Region, operated by Sinnet",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/07/amazon-dynamodb-accelerator-dax-is-now-available-in-the-china-beijing-region/", 
      "date": "7/28/2021"
    },
    {
      "title": "Amazon DynamoDB global tables are now available in the Asia Pacific (Osaka) Region",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/07/amazon-dynamodb-global-tables-are-now-available-in-the-asia-paci/", 
      "date": "7/28/2021"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) now supports encryption in transit of data between your applications and DAX clusters, and between the nodes within a DAX cluster",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/06/amazon-dynamodb-accelerator--dax--now-supports-encryption-in-tra/", 
      "date": "6/24/2021"
    },
    {
      "title": "Amazon DynamoDB global tables now support AWS CloudFormation",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/05/amazon-dynamodb-global-tables-now-support-aws-cloudformation/", 
      "date": "5/17/2021"
    },
    {
      "title": "Amazon DynamoDB local now supports the AWS SDK for Java 2.x",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/05/amazon-dynamodb-local-now-supports-the-aws-sdk-for-java-2-x/", 
      "date": "5/03/2021"
    },
    {
      "title": "You now can use AWS CloudTrail to log Amazon DynamoDB Streams data-plane API activity",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/04/you-now-can-use-aws-cloudtrail-to-log-amazon-dynamodb-streams-da/", 
      "date": "4/20/2021"
    },
    {
      "title": "Amazon Kinesis Data Streams for Amazon DynamoDB now supports AWS CloudFormation",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/04/amazon-kinesis-data-streams-for-amazon-dynamodb-now-supports-aws/", 
      "date": "4/12/2021"
    },
    {
      "title": "AWS CloudTrail Adds Logging of Data Events for Amazon DynamoDB",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/03/aws-cloudtrail-adds-logging-of-data-events-for-amazon-dynamoDB/", 
      "date": "3/24/2021"
    },
    {
      "title": "Now you can use AWS CloudTrail to log data-plane API activity to monitor, alarm, and archive item-level activity in your Amazon DynamoDB tables",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/03/now-you-can-use-aws-cloudtrail-to-log-data-plane-api-activity-in-your-amazon-dynamodb-tables/", 
      "date": "3/24/2021"
    },
    {
      "title": "Amazon CloudWatch Contributor Insights for Amazon DynamoDB now supports AWS CloudFormation",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/02/amazon-cloudwatch-contributor-insights-for-amazon-dynamodb-now-supports-aws-cloudformation/", 
      "date": "2/23/2021"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) now supports next generation, burstable general-purpose Amazon EC2 T3 instance types",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/02/amazon-dynamodb-accelerator-dax-now-supports-amazon-ec2-t3-instance-types/", 
      "date": "2/15/2021"
    },
    {
      "title": "You now can use PartiQL with DynamoDB local to query, insert, update, and delete table data in Amazon DynamoDB",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/02/you-now-can-use-partiql-with-dynamodb-local-to-query-insert-update-and-delete-table-data-in-amazon-dynamodb/", 
      "date": "2/08/2021"
    },
    {
      "title": "Amplify DataStore launches support for sorting and selectively syncing app data",
      "link": "https://aws.amazon.com/about-aws/whats-new/2021/01/amplify-datastore-launches-support-sorting-selectively-syncing-app-data/", 
      "date": "1/14/2021"
    },
    {
      "title": "PartiQL for DynamoDB now is supported in 23 AWS Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/12/partiql-for-dynamodb-now-is-supported-in-23-aws-regions/", 
      "date": "12/21/2020"
    },
    {
      "title": "You now can use Amazon DynamoDB with AWS Glue Elastic Views to combine and replicate data across multiple data stores by using SQL - available in limited preview",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/12/you-now-can-use-amazon-dynamodb-with-aws-glue-elastic-views-available-in-limited-preview/", 
      "date": "12/01/2020"
    },
    {
      "title": "You now can use a SQL-compatible query language to query, insert, update, and delete table data in Amazon DynamoDB",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/11/you-now-can-use-a-sql-compatible-query-language-to-query-insert-update-and-delete-table-data-in-amazon-dynamodb/", 
      "date": "11/23/2020"
    },
    {
      "title": "Now you can use Amazon Kinesis Data Streams to capture item-level changes in your Amazon DynamoDB tables",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/11/now-you-can-use-amazon-kinesis-data-streams-to-capture-item-level-changes-in-your-amazon-dynamodb-table/", 
      "date": "11/23/2020"
    },
    {
      "title": "You now can restore Amazon DynamoDB tables even faster when recovering from data loss or corruption",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/11/you-now-can-restore-amazon-dynamodb-tables-even-faster-when-recovering-from-data-loss-or-corruption/", 
      "date": "11/23/2020"
    },
    {
      "title": "AWS Pricing Calculator now supports Amazon DynamoDB",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/11/aws-pricing-calculator-now-supports-amazon-dynamodb/", 
      "date": "11/23/2020"
    },
    {
      "title": "Now you can export your Amazon DynamoDB table data to your data lake in Amazon S3 to perform analytics at any scale",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/11/now-you-can-export-your-amazon-dynamodb-table-data-to-your-data-lake-in-amazon-s3-to-perform-analytics-at-any-scale/", 
      "date": "11/09/2020"
    },
    {
      "title": "Encrypt your Amazon DynamoDB global tables by using your own encryption keys",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/11/encrypt-your-amazon-dynamodb-global-tables-by-using-your-own-encryption-keys/", 
      "date": "11/06/2020"
    },
    {
      "title": "Amazon DynamoDB global tables are now available in the Europe (Milan) and Europe (Stockholm) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/10/amazon-dynamodb-global-tables-are-now-available-in-the-europe-milan-and-europe-stockholm-regions/", 
      "date": "10/06/2020"
    },
    {
      "title": "You now can restore Amazon DynamoDB table backups as new tables in the Africa (Cape Town), Asia Pacific (Hong Kong), Europe (Milan), and Middle East (Bahrain) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/09/you-now-can-restore-amazon-dynamodb-table-backups-as-new-tables-in-cape-town-hong-kong-milan-bahrain/", 
      "date": "9/23/2020"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) now supports next-generation, memory-optimized Amazon EC2 R5 nodes",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/08/amazon-dynamodb-accelerator-dax-now-supports-next-generation-memory-optimized-amazon-ec2-r5-nodes/", 
      "date": "8/11/2020"
    },
    {
      "title": "CloudWatch Application Insights now supports MySQL, Amazon DynamoDB, custom logs, and more",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/06/cloudwatch-application-insights-supports-mysql-amazon-dynamodb-custom-logs-more/", 
      "date": "6/08/2020"
    },
    {
      "title": "Amazon DynamoDB support for empty values for non-key String and Binary attributes in DynamoDB tables is now available in the AWS GovCloud (US) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/06/amazon-dynamodb-support-for-empty-values-for-non-key-string-and-binary-attributes-in-dynamodb-tables-is-now-available-in-the-aws-govcloud-us-regions/", 
      "date": "6/01/2020"
    },
    {
      "title": "Amazon DynamoDB local adds support for empty values for non-key String and Binary attributes and 25-item transactions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/05/amazon-dynamodb-local-adds-support-for-empty-values-for-non-key-string-and-binary-attributes-and-25-item-transactions/", 
      "date": "5/21/2020"
    },
    {
      "title": "Amazon DynamoDB now supports empty values for non-key String and Binary attributes in DynamoDB tables",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/05/amazon-dynamodb-now-supports-empty-values-for-non-key-string-and-binary-attributes-in-dynamodb-tables/", 
      "date": "5/18/2020"
    },
    {
      "title": "Amazon DynamoDB global tables are now available in the AWS China (Beijing) Region, operated by Sinnet, and the AWS China (Ningxia) Region, operated by NWCD",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/04/amazon-dynamodb-global-tables-are-now-available-in-the-aws-china-beijing-region-operated-by-sinnet-and-the-aws-china-ningxia-region-operated-by-nwcd/",
      "date": "4/8/2020"
    },
    {
      "title": "Amazon CloudWatch Contributor Insights for Amazon DynamoDB is now available in the AWS GovCloud (US) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/04/amazon-cloudwatch-contributor-insights-for-amazon-dynamodb-is-now-available-in-the-aws-govcloud-us-regions/",
      "date": "4/2/2020"
    },
    {
      "title": "Amazon CloudWatch Contributor Insights for Amazon DynamoDB is now generally available",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/04/amazon-cloudwatch-contributor-insights-for-amazon-dynamodb-is-now-generally-available/",
      "date": "4/2/2020"
    },
    {
      "title": "You now can update your Amazon DynamoDB global tables from version 2017.11.29 to the latest version with a few clicks in the DynamoDB Console",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/03/you-now-can-update-your-amazon-dynamodb-global-tables-from-version-2017-11-29-to-the-latest-version-with-a-few-clicks-in-the-dynamodb-console/",
      "date": "3/16/2020"
    },
    {
      "title": "Amazon DynamoDB on-demand capacity mode is now available in the Asia Pacific (Osaka-Local) Region",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/03/amazon-dynamodb-on-demand-capacity-mode-is-now-available-in-the-asia-pacific-osaka-local-region/",
      "date": "3/16/2020"
    },
    {
      "title": "You can now restore Amazon DynamoDB table backups as new tables in other AWS Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/02/you-can-now-restore-amazon-dynamodb-table-backups-as-new-tables-in-other-aws-regions/",
      "date": "2/18/2020"
    },
    {
      "title": "Introducing Multi-Region Asynchronous Object Replication Solution",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/02/introducing-multi-region-asynchronous-object-replication-solution/",
      "date": "2/11/2020"
    },
    {
      "title": "Use the new Amazon CloudWatch metrics for Amazon DynamoDB Accelerator (DAX) to gain more insights into your DAX clusters’ performance",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/02/use-the-new-amazon-cloudwatch-metrics-for-amazon-dynamodb-accelerator-dax-to-gain-more-insights-into-your-dax-clusters-performance/",
      "date": "2/6/2020"
    },
    {
      "title": "DynamoDB global tables are now available in the Asia Pacific (Mumbai), Canada (Central), EU (Paris), and South America (Sao Paulo) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2020/02/dynamodb-global-tables-are-now-available-in-the-asia-pacific-mumbai-canada-central-eu-paris-and-south-america-sao-paulo-regions/",
      "date": "2/6/2020"
    },
    {
      "title": "Amazon CloudWatch Contributor Insights for Amazon DynamoDB (Preview) is now available in 15 additional AWS Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/12/amazon-cloudwatch-contributor-insights-for-amazon-dynamodb-preview-is-now-available-in-15-additional-aws-regions/",
      "date": "12/16/2019"
    },
    {
      "title": "New Amazon CloudWatch Contributor Insights for Amazon DynamoDB (Preview) helps you identify frequently accessed keys and database traffic trends",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/11/new-amazon-cloudwatch-contributor-insights-for-amazon-dynamodb-preview-helps-you-identify-frequently-accessed-keys-and-database-traffic-trends/",
      "date": "11/26/2019"
    },
    {
      "title": "Encrypt your Amazon DynamoDB data by using your own encryption keys",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/11/encrypt-your-amazon-dynamodb-data-by-using-your-own-encryption-keys/",
      "date": "11/26/2019"
    },
    {
      "title": "AWS AppSync adds server-side Caching and DynamoDB transactions support for GraphQL APIs",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/11/aws-appsync-adds-server-side-caching-and-dynamodb-transactions-support-for-graphql-apis/",
      "date": "11/21/2019"
    },
    {
      "title": "You now can enhance availability of your existing Amazon DynamoDB tables by adding global replicas",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/11/you-can-now-enhance-availability-of-your-existing-amazon-dynamodb-tables-by-adding-global-replicas/",
      "date": "11/21/2019"
    },
    {
      "title": "Amazon DynamoDB adaptive capacity now handles imbalanced workloads better by isolating frequently accessed items automatically",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/11/amazon-dynamodb-adaptive-capacity-now-handles-imbalanced-workloads-better-by-isolating-frequently-accessed-items-automatically/",
      "date": "11/15/2019"
    },
    {
      "title": "You now can configure table settings when you restore a table from your Amazon DynamoDB backups",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/11/you-can-now-configure-table-settings-when-you-restore-a-table-from-your-amazon-dynamodb-backups/",
      "date": "11/13/2019"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) is now available in the China (Ningxia) Region, Operated by NWCD",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/11/amazon-dynamodb-accelerator-dax-is-now-available-in-the-china-ningxia-region-operated-by-nwcd/",
      "date": "11/13/2019"
    },
    {
      "title": "You can now create 20 global secondary indexes and have 100 projected index attributes per Amazon DynamoDB table in AWS GovCloud (US)",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/11/you-can-now-create-20-global-secondary-indexes-and-have-100-projected-index-attributes-per-amazon-dynamodb-table-in-aws-govcloud-us/",
      "date": "11/13/2019"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) is now available in the EU (London) and EU (Paris) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/11/amazon-dynamodb-accelerator-dax-is-now-available-in-the-eu-london-and-eu-paris-regions/",
      "date": "11/11/2019"
    },
    {
      "title": "DynamoDBMapper now supports optimistic locking for Amazon DynamoDB transactional API calls",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/10/dynamodbmapper-now-supports-optimistic-locking-for-amazon-dynamodb-transactional-api-calls/",
      "date": "10/1/2019"
    },
    {
      "title": "Amazon DynamoDB now helps you monitor as you approach your account limits",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/08/amazon-dynamodb-now-helps-you-monitor-as-you-approach-your-account-limits/",
      "date": "8/5/2019"
    },
    {
      "title": "Amplify Framework now Supports Adding AWS Lambda Triggers for events in Auth and Storage categories",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/07/amplify-framework-now-supports-adding-aws-lambda-triggers-for-events-auth-storage-categories/",
      "date": "7/9/2019"
    },
    {
      "title": "Amazon DynamoDB now supports deleting a global secondary index before it finishes building",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/07/amazon-dynamodb-now-supports-deleting-a-global-secondary-index-before-it-finishes-building/",
      "date": "7/2/2019"
    },
    {
      "title": "Amazon DynamoDB now supports up to 25 unique items and 4 MB of data per transactional request in the AWS China (Beijing) Region, Operated by Sinnet, and the AWS China (Ningxia) Region, Operated by NWCD",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/07/amazon-dynamodb-now-supports-up-to-25-unique-items-and-4-mb-of-data-per-transactional-request-in-the-aws-china-beijing-region-operated-by-sinnet-and-the-aws-china-ningxia-region-operated-by-nwcd/",
      "date": "7/1/2019"
    },
    {
      "title": "Amazon DynamoDB now supports up to 25 unique items and 4 MB of data per transactional request",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/06/amazon-dynamodb-now-supports-up-to-25-unique-items-and-4-mb-of-data-per-transactional-request/",
      "date": "6/24/2019"
    },
    {
      "title": "Amazon DynamoDB on-demand and transactions now available in the AWS GovCloud (US) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/06/amazon-dynamodb-on-demand-and-transactions-now-available-in-the-aws-govcloud-us-regions/",
      "date": "6/11/2019"
    },
    {
      "title": "Amazon DynamoDB now supports tagging tables when you create them in the AWS GovCloud (US) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/06/amazon-dynamodb-now-supports-tagging-tables-when-you-create-them-in-the-aws-govcloud-us-regions/",
      "date": "6/11/2019"
    },
    {
      "title": "New Digital Course on edX for Building Applications That Use Amazon DynamoDB",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/06/new-digital-course-on-edx-learn-to-build-applications-with-amazon-dynamodb/",
      "date": "6/7/2019"
    },
    {
      "title": "Amazon DynamoDB supports FIPS 140-2 validated endpoints in the Canada (Central) Region",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/05/amazon-dynamodb-supports-fips-140-2-validated-endpoints-in-the-canada-central-region/",
      "date": "5/28/2019"
    },
    {
      "title": "Amazon DynamoDB adaptive capacity is now instant",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/05/amazon-dynamodb-adaptive-capacity-is-now-instant/",
      "date": "5/23/2019"
    },
    {
      "title": "Amazon DynamoDB transactions are now available in the Asia Pacific (Osaka-Local) Region",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/05/amazon-dynamodb-transactions-are-now-available-in-the-asia-pacific-osaka-local-region/",
      "date": "5/14/2019"
    },
    {
      "title": "DynamoDBMapper now supports Amazon DynamoDB transactional API calls",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/04/dynamodbmapper-now-supports-amazon-dynamodb-transactional-api-calls/",
      "date": "4/26/2019"
    },
    {
      "title": "Now you can tag Amazon DynamoDB tables when you create them",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/04/now-you-can-tag-amazon-dynamodb-tables-when-you-create-them/",
      "date": "4/26/2019"
    },
    {
      "title": "AWS specifies the IP address ranges for Amazon DynamoDB endpoints",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/04/aws-specifies-the-ip-address-ranges-for-amazon-dynamodb-endpoints/",
      "date": "4/25/2019"
    },
    {
      "title": "AWS Backup is Now Available in the AWS Asia Pacific (Sydney) and EU (Frankfurt) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/04/introducing-aws-backup/",
      "date": "4/4/2019"
    },
    {
      "title": "Amazon DynamoDB drops the price of global tables by eliminating associated charges for DynamoDB Streams",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/04/amazon-dynamodb-drops-the-price-of-global-tables-by-eliminating-associated-charges-for-dynamodb-streams/",
      "date": "4/1/2019"
    },
    {
      "title": "Amazon DynamoDB encryption at rest is now available in the AWS GovCloud (US) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/03/amazon-dynamodb-encryption-at-rest-is-now-available-in-the-aws-govcloud-us-regions/",
      "date": "3/29/2019"
    },
    {
      "title": "Amazon DynamoDB backup and restore is now available in AWS GovCloud (US)",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/03/amazon-dynamodb-backup-and-restore-is-now-available-in-aws-govcloud-us/",
      "date": "3/28/2019"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) expands to the EU (Frankfurt) Region, and adds support for R4 instance types in the US West (N. California) Region",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/02/amazon-dynamodb-accelerator-dax-expands-to-the-eu-frankfurt-region-and-adds-support-for-r4-instance-types-in-the-us-west-n-california-region/",
      "date": "2/20/2019"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) adds DynamoDB transactions support for Python and .NET",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/02/amazon-dynamodb-accelerator-dax-adds-dynamodb-transactions-support-for-python-and-net/",
      "date": "2/14/2019"
    },
    {
      "title": "Amazon DynamoDB Local Adds Support for Transactional APIs, On-Demand Capacity Mode, and 20 GSIs",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/02/amazon-dynamodb-local-adds-support-for-transactional-apis-on-demand-capacity-mode-and-20-gsis/",
      "date": "2/4/2019"
    },
    {
      "title": "AWS Backup Integrates with Amazon DynamoDB for Centralized and Automated Backup Management",
      "link": "https://aws.amazon.com/about-aws/whats-new/2019/01/aws-backup-integrates-with-amazon-DynamoDB-for-centralized-and-automated-backup-management/",
      "date": "1/16/2019"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) Adds Support for DynamoDB Transactions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/12/amazon-dynamodb-accelerator-adds-support-for-dynamodb-transactions/",
      "date": "12/20/2018"
    },
    {
      "title": "Amazon DynamoDB Increases the Number of Global Secondary Indexes and Projected Index Attributes You Can Create Per Table",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/12/amazon-dynamodb-increases-the-number-of-global-secondary-indexes-and-projected-index-attributes-you-can-create-per-table/",
      "date": "12/19/2018"
    },
    {
      "title": "Announcing the New Amazon DynamoDB Key Diagnostics Library",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/12/announcing-the-new-amazon-dynamodb-key-diagnostics-library/",
      "date": "12/13/2018"
    },
    {
      "title": "AWS Step Functions Adds Eight More Service Integrations",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/11/aws-step-functions-adds-eight-more-service-integrations/",
      "date": "11/29/2018"
    },
    {
      "title": "Announcing Amazon DynamoDB On-Demand",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/11/announcing-amazon-dynamodb-on-demand/",
      "date": "11/28/2018"
    },
    {
      "title": "Announcing Amazon DynamoDB Support for Transactions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/11/announcing-amazon-dynamodb-support-for-transactions/",
      "date": "11/27/2018"
    },
    {
      "title": "Amazon DynamoDB Backup and Restore Now Available in the AWS China (Ningxia) Region, Operated by NWCD",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/11/amazon-dynamodb-backup-and-restore-now-available-in-aws-china-ningxia/",
      "date": "11/19/2018"
    },
    {
      "title": "Amazon DynamoDB encrypts all customer data at rest",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/11/amazon-dynamodb-encrypts-all-customer-data-at-rest/",
      "date": "11/15/2018"
    },
    {
      "title": "Amazon DynamoDB Encryption at Rest Regional Expansion",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/09/amazon-dynamodb-encryption-at-rest-regional-expansion/",
      "date": "9/12/2018"
    },
    {
      "title": "Amazon DynamoDB Global Tables Available in Additional Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/08/amazon-dynamodb-global-tables-available-in-additional-regions/",
      "date": "8/27/2018"
    },
    {
      "title": "Use Amazon DynamoDB Local More Easily with the New Docker Image",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/08/use-amazon-dynamodb-local-more-easily-with-the-new-docker-image/",
      "date": "8/22/2018"
    },
    {
      "title": "Amazon DynamoDB Backup and Restore Now Available in the AWS China (Beijing) Region, Operated by Sinnet",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/08/amazon-dynamodb-backup-and-restore-now-available-in-aws-china/",
      "date": "8/14/2018"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) Adds Support for Encryption at Rest",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/08/amazon-dynamodb-accelerator--dax--adds-support-for-encryption-at/",
      "date": "8/9/2018"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) r4 Instance Types Available in the Asia Pacific (Tokyo) Region",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/07/amazon-dynamodb-accelerator-instance-types-available-in-tokyo/",
      "date": "7/26/2018"
    },
    {
      "title": "Amazon DynamoDB Global Tables Now Available in Three Additional Asia Pacific Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/07/amazon-dynamodb-global-tables-regional-expansion/",
      "date": "7/25/2018"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) SDK Enhancements",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/07/amazon-dynamodb-accelerator--dax--sdk-enhancements/",
      "date": "7/19/2018"
    },
    {
      "title": "Amazon DynamoDB Backup and Restore Regional Expansion",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/06/amazon-dynamodb-backup-and-restore-regional-expansion/",
      "date": "6/28/2018"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) SDK for Go Now Available",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/06/amazon-dynamodb-accelerator--dax--sdk-for-go-now-available/",
      "date": "6/26/2018"
    },
    {
      "title": "Amazon DynamoDB Announces 99.999% Service Level Agreement for Global Tables",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/06/amazon-dynamodb-announces-a-monthly-service-level-agreement/",
      "date": "6/19/2018"
    },
    {
      "title": "Amazon DynamoDB Encryption Client Is Now Available in Python",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/05/amazon-dynamodb-encryption-client-available-in-python/",
      "date": "5/3/2018"
    },
    {
      "title": "Amazon DynamoDB Global Tables Regional Expansion",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/04/amazon-dynamodb-global-tables-regional-expansion/",
      "date": "4/30/2018"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) Regional Expansion",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/04/amazon-dynamodb-accelerator-regional-expansion/",
      "date": "4/26/2018"
    },
    {
      "title": "Amazon DynamoDB Encryption at Rest Now Available in Additional Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/04/amazon-dynamodb-encryption-at-rest-now-available-in-additonal-regions/",
      "date": "4/19/2018"
    },
    {
      "title": "Amazon DynamoDB Adds Support for Continuous Backups and Point-In-Time Recovery (PITR)",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/03/amazon-dynamodb-adds-support-for-continuous-backups-and-point-in-time-recovery/",
      "date": "3/26/2018"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) Releases SDKs for Python and .NET, Support for T2 Instances, and now available in the Asia Pacific (Singapore) and Asia Pacific (Sydney) Regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/02/amazon-dynamodb-accelerator-dax-releases-sdks-for-python-and-dot-net-support-for-t2-instances-and-now-available-in-the-asia-pacific-singapore-and-asia-pacific-sydney-regions/",
      "date": "2/13/2018"
    },
    {
      "title": "Amazon DynamoDB Now Supports Server-Side Encryption at Rest",
      "link": "https://aws.amazon.com/about-aws/whats-new/2018/02/amazon-dynamodb-now-supports-server-side-encryption-at-rest/",
      "date": "2/8/2018"
    },
    {
      "title": "AWS Launches Amazon DynamoDB Backup and Restore",
      "link": "https://aws.amazon.com/about-aws/whats-new/2017/11/aws-launches-amazon-dynamodb-backup-and-restore/",
      "date": "11/29/2017"
    },
    {
      "title": "AWS Launches Amazon DynamoDB Global Tables",
      "link": "https://aws.amazon.com/about-aws/whats-new/2017/11/aws-launches-amazon-dynamodb-global-tables/",
      "date": "11/29/2017"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) releases SDK for Node.js, support for R4 instances and general availability in Asia Pacific (Mumbai) and South America (São Paulo) regions",
      "link": "https://aws.amazon.com/about-aws/whats-new/2017/10/amazon-dynamodb-accelerator-dax-releases-sdk-for-node-js-support-for-r4-instances-and-general-availability-in-asia-pacific-mumbai-and-south-america-sao-paulo-regions/",
      "date": "10/5/2017"
    },
    {
      "title": "AWS Config Adds Support for Amazon DynamoDB Tables",
      "link": "https://aws.amazon.com/about-aws/whats-new/2017/09/aws-config-adds-support-for-amazon-dynamodb-tables/",
      "date": "9/8/2017"
    },
    {
      "title": "AWS CloudFormation support for Amazon DynamoDB Accelerator (DAX)",
      "link": "https://aws.amazon.com/about-aws/whats-new/2017/08/aws-cloudformation-support-for-amazon-dynamodb-accelerator-dax/",
      "date": "8/22/2017"
    },
    {
      "title": "VPC Endpoints for Amazon DynamoDB Now Generally Available",
      "link": "https://aws.amazon.com/about-aws/whats-new/2017/08/vpc-endpoints-for-amazon-dynamodb-now-generally-available/",
      "date": "8/16/2017"
    },
    {
      "title": "Amazon DynamoDB Accelerator (DAX) Now Generally Available",
      "link": "https://aws.amazon.com/about-aws/whats-new/2017/06/amazon-dynamodb-accelerator-dax-now-generally-available/",
      "date": "6/21/2017"
    },
    {
      "title": "Announcing Amazon DynamoDB Auto Scaling",
      "link": "https://aws.amazon.com/about-aws/whats-new/2017/06/announcing-amazon-dynamodb-auto-scaling/",
      "date": "6/14/2017"
    },
    {
      "title": "Amazon DynamoDB: Titan Graph Database Integration",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/12/amazon-dynamodb-titan-graph-database-integration/",
      "date": "12/14/2015"
    },
    {
      "title": "DynamoDB Console Redesign",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/11/dynamodb-console-redesign/",
      "date": "11/12/2015"
    },
    {
      "title": "Amazon DynamoDB: Develop Locally, Scale Globally",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/09/amazon-dynamodb-develop-locally-scale-globally/",
      "date": "9/10/2015"
    },
    {
      "title": "DynamoDB Update: Enhanced metrics for better operational insight",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/08/dynamodb-update-enhanced-metrics-for-better-operational-insight/",
      "date": "8/24/2015"
    },
    {
      "title": "Amazon DynamoDB – Titan Graph Database Integration",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/08/amazon-dynamodb-titan-graph-database-integration/",
      "date": "8/20/2015"
    },
    {
      "title": "Amazon DynamoDB: Elasticsearch Integration",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/08/amazon-dynamodb-elasticsearch-integration/",
      "date": "8/17/2015"
    },
    {
      "title": "Amazon DynamoDB, Amazon RDS (Oracle and MySQL engines only), and Amazon EMR now covered under the AWS Business Associate Agreement",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/07/amazon-dynamodb-amazon-rds-oracle-mysql-engines-amazon-emr-covered-under-aws-business-associate-agreement/",
      "date": "7/28/2015"
    },
    {
      "title": "DynamoDB Update: Scan Operation Supports Consistent Reads",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/07/dynamodb-update-scan-operation-supports-consistent-reads/",
      "date": "7/22/2015"
    },
    {
      "title": "Amazon DynamoDB: Available now – Cross-region Replication, Triggers, and Streams",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/07/amazon-dynamodb-available-now-cross-region-replication-triggers-and-streams/",
      "date": "7/16/2015"
    },
    {
      "title": "Amazon DynamoDB supports AWS CloudTrail",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/05/amazon-dynamodb-supports-aws-cloudtrail/",
      "date": "5/28/2015"
    },
    {
      "title": "DynamoDB update: Expressions in Key Conditions and improved console support for JSON",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/04/dynamodb-update-expressions-in-key-conditions-and-improved-console-support-for-json/",
      "date": "4/27/2015"
    },
    {
      "title": "Secondary Index Scan – A simpler way to scan DynamoDB table",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/02/10/secondary-index-scan-a-simpler-way-to-scan-dynamodb-table/",
      "date": "2/10/2015"
    },
    {
      "title": "Flexible Options for Managing Amazon DynamoDB Reserved",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2015/01/12/flexible-options-for-managing-amazon-dynamodb-reserved/",
      "date": "1/12/2015"
    },
    {
      "title": "Introducing DynamoDB Streams",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2014/11/10/introducing-dynamodb-streams/",
      "date": "11/10/2014"
    },
    {
      "title": "Amazon DynamoDB now supports JSON document data structure and large items",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2014/10/08/amazon-dynamodb-now-supports-json-document-data-structure-and-large-items/",
      "date": "10/8/2014"
    },
    {
      "title": "AWS Console Mobile App Adds Support for Amazon DynamoDB",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2014/09/24/aws-console-mobile-app-adds-support-amazon-dynamodb/",
      "date": "9/24/2014"
    },
    {
      "title": "New Free Videos & Labs for DynamoDB, Elastic Beanstalk, and Elastic MapReduce",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2014/08/14/new-free-videos-and-labs-for-dynamodb-elastic-beanstalk-and-elastic-mapreduce/",
      "date": "8/13/2014"
    },
    {
      "title": "Announcing DynamoDB Cross-Region Export/Import",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2014/03/06/announcing-dynamodb-cross-region-export-import/",
      "date": "3/6/2014"
    },
    {
      "title": "CloudFormation supports Auto Scaling Scheduled Actions and DynamoDB Secondary Indexes",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2014/01/27/aws-cloudformation-supports-auto-scaling-scheduled-actions-and-dynamodb-secondary-indexes/",
      "date": "1/27/2014"
    },
    {
      "title": "Amazon DynamoDB Console now available in AWS GovCloud (US)",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2013/12/30/amazon-dynamodb-console-now-available-in-aws-govcloud-us/",
      "date": "12/30/2013"
    },
    {
      "title": "New Query Capability in DynamoDB - Global Secondary Indexes Enable Queries on any Field",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2013/12/12/announcing-amazon-dynamodb-global-secondary-indexes/",
      "date": "12/12/2013"
    },
    {
      "title": "Announcing Fine-Grained Access Control for Amazon DynamoDB",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2013/10/31/announcing-amazon-dynamodb-fine-grained-access-control/",
      "date": "10/31/2013"
    },
    {
      "title": "Announcing DynamoDB Cross-Region Copy feature in AWS Data Pipeline",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2013/09/12/announcing-dynamodb-cross-region-copy-feature-in-aws-data-pipeline/",
      "date": "9/12/2013"
    },
    {
      "title": "Announcing DynamoDB Local Test Tool",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2013/09/12/amazon-dynamodb-local/",
      "date": "9/12/2013"
    },
    {
      "title": "Announcing Geospatial Indexing Library for Amazon DynamoDB",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2013/09/05/announcing-amazon-dynamodb-geospatial-indexing/",
      "date": "9/5/2013"
    },
    {
      "title": "Amazon DynamoDB Announces Parallel Scan and Lower-Cost Reads",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2013/05/15/dynamodb-announces-parallel-scan-and-lower-cost-reads/",
      "date": "5/15/2013"
    },
    {
      "title": "Amazon DynamoDB Announces Support for Local Secondary Indexes",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2013/04/18/amazon-dynamodb-announces-local-secondary-indexes/",
      "date": "4/18/2013"
    },
    {
      "title": "Amazon DynamoDB Reduces Prices",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2013/03/07/lower-pricing-for-dynamodb/",
      "date": "3/7/2013"
    },
    {
      "title": "Amazon DynamoDB Announces Binary Data Types",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/08/21/dynamodb-announces-binary-data-types/",
      "date": "8/21/2012"
    },
    {
      "title": "Amazon DynamoDB Announces In-Console Item Updating",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/08/14/dynamodb-announces-in-console-item-updating/",
      "date": "8/14/2012"
    },
    {
      "title": "Amazon DynamoDB Announces Lower Minimum Provisioned Table Throughput",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/08/09/dynamodb-announces-minimumtablethroughput/",
      "date": "8/9/2012"
    },
    {
      "title": "Amazon DynamoDB Breaks AWS Growth Records",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/06/26/dynamodb-breaks-growth-records/",
      "date": "6/26/2012"
    },
    {
      "title": "AWS CloudFormation Supports Amazon DynamoDB and Amazon CloudFront Dynamic Content",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/06/19/aws-cloudformation-supports-amazon-dynamodb-and-amazon-cloudfront-dynamic-content/",
      "date": "6/19/2012"
    },
    {
      "title": "Amazon DynamoDB Now Available in Three Additional Regions",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/04/24/dynamodb-now-available-in-three-additional-regions/",
      "date": "4/24/2012"
    },
    {
      "title": "Amazon DynamoDB Announces BatchWriteItem Feature",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/04/19/dynamodb-announces-batchwriteitem/",
      "date": "4/19/2012"
    },
    {
      "title": "Amazon DynamoDB Now Available in Europe",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/03/07/dynamodb-now-available-in-europe/",
      "date": "3/7/2012"
    },
    {
      "title": "Amazon DynamoDB Now Available in Japan",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/02/29/dynamodb-now-available-in-japan/",
      "date": "2/29/2012"
    },
    {
      "title": "AWS Announces DynamoDB",
      "link": "https:///aws.amazon.com/about-aws/whats-new/2012/01/18/aws-announces-dynamodb/",
      "date": "1/18/2012"
    }
  ]