import React from 'react';
import Reactable from 'reactable'
import data from '@components/dynamodb/Data.js'
import ParseDate from '@fragments/ParseDate.js'
const Table = Reactable.Table;
const unsafe = Reactable.unsafe;

const Releases = (props) => {
    const cleanedData = []
    data.forEach(element => {
        const {date, title, link} = element;
        const { da, mo, ye} = ParseDate(date);

        cleanedData.push({Date: unsafe(`${da} ${mo} ${ye.replace("20", "")}`), Title: unsafe(`<a href=${link} target="_blank" rel='noopener noreferrer'>${title}</a>`)})
    });

    return (
        <Table className="table" id="dynamodb-releases" data={cleanedData}
        itemsPerPage={5} /> 
    );
}
export default Releases;